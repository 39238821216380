import http from '../http';

	export interface SpaceCreator {
		uid: string;
		name: string;
		avatar: string;
}
	
export interface OpenSpace {
	spaceId: string;
	name: string;
	avatar: string;
	description: string;
	createTime: number;
	creator: SpaceCreator;

	trend: number;
	topicNum: number;
	pageNum: number;
	commentNum: number;
}

export interface OpenSpaceResp {
	list: OpenSpace[];
}

export const getOpenSpaceList = (page: number, pageSize: number) =>
  http.get<OpenSpaceResp>(`/v3/space/openSpace`, { page, pageSize }, true, true);