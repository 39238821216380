import { BaseResponse } from '../types';
import intl from 'react-intl-universal';

export interface InfoItem {
	id: string;
	title: string;
	subtitle: string;
	titleImage: string;
	image: string;
	author: string;
	authorAvatar: string;
	createTime: string;
	content: string;
}

export interface Info {
	classify: string;
	icon: string;
	moreUrl: string;
	list: InfoItem[]
}

export interface Infos {
	list: Info[];
}

type PromiseCbkLoadInfos = (arg: BaseResponse<Infos>) => void;
type PromiseCbkInfo = (arg: BaseResponse<InfoItem>) => void;

const getLocalInfos =
	() => (resolve: PromiseCbkLoadInfos, reject: PromiseCbkLoadInfos) => {
	let year = intl.get("year");
	let month = intl.get("month");
	let day = intl.get("day");
	
		let infos: Infos = {
			list: [
				{
					classify: intl.get('classifyProduct'),
					icon: '/assets/images/infos/classify_product.png',
					moreUrl: '',
					list: [
						{
							id: 'ad1230cda73dc0aec21562025030115',
							title: intl.get('infoPrduct2Title'),
							subtitle: intl.get(`infoPrduct2Subtitle`),
							content: intl.get(`infoPrduct2Content`),
							titleImage: '/assets/images/infos/product_2_title.png',
							image: '',
							author: intl.get(`zhiyaProductTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2025${year}3${month}10${day}`,
						},
						{
							id: 'bd5150cda73dc0aec21562025010105',
							title: intl.get('infoPrduct1Title'),
							subtitle: intl.get(`infoPrduct1Subtitle`),
							content: intl.get(`infoPrduct1Content`),
							titleImage: '/assets/images/infos/product_1_title.png',
							image: '',
							author: intl.get(`zhiyaProductTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2025${year}1${month}5${day}`,
						},
						{
							id: 'bd5150cda73dc0aec21569b1909ab001',
							title: intl.get('blog1Title'),
							subtitle: intl.get(`blog1Subtitle`),
							content: intl.get(`blog1Content`),
							titleImage: '/assets/images/infos/blog_1.png',
							image: '/assets/images/infos/blog_1.png',
							author: intl.get(`zhiyaProductTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2024${year}12${month}4${day}`,
						}
					],
				},
				{
					classify: intl.get('classifyNews'),
					icon: '/assets/images/infos/classify_news.png',
					moreUrl: '',
					list: [
					{
							id: 'ab18bcde73uc0abc21569b14d9ac56456',
							title: intl.get('news2Title'),
							subtitle: intl.get(`news2Subtitle`),
							content: intl.get(`news2Content`),
							titleImage: '/assets/images/infos/news_2_title.png',
							image: '/assets/images/infos/news_2.png',
							author: intl.get(`zhiyaOperationTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2025${year}03${month}24${day}`,
						},
					{
							id: '5618bcde73uc0abc21569b14d9ac56123',
							title: intl.get('news1Title'),
							subtitle: intl.get(`news1Subtitle`),
							content: intl.get(`news1Content`),
							titleImage: '/assets/images/infos/news_1_title.png',
							image: '/assets/images/infos/news_1.png',
							author: intl.get(`zhiyaOperationTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2025${year}03${month}12${day}`,
						},
						{
							id: 'da18bcde73uc0abc21569b14d9ac56bdc',
							title: intl.get('blog12Title'),
							subtitle: intl.get(`blog12Subtitle`),
							content: intl.get(`blog12Content`),
							titleImage: '/assets/images/infos/blog_12_title.png',
							image: '/assets/images/infos/blog_12.png',
							author: intl.get(`zhiyaOperationTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2024${year}12${month}27${day}`,
						},
						{
							id: 'ad5b50cde73uc0abc21569b14d9ac002',
							title: intl.get('blog10Title'),
							subtitle: intl.get(`blog10Subtitle`),
							content: intl.get(`blog10Content`),
							titleImage: '/assets/images/infos/blog_10_title.png',
							image: '/assets/images/infos/blog_10.jpg',
							author: intl.get(`zhiyaOperationTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2024${year}11${month}29${day}`,
						},
						{
							id: 'ab1cb0cdec3uc0a5821569b14d9db002',
							title: intl.get('blog11Title'),
							subtitle: intl.get(`blog11Subtitle`),
							content: intl.get(`blog11Content`),
							titleImage: '/assets/images/infos/blog_11_title.png',
							image: '/assets/images/infos/blog_11.png',
							author: intl.get(`zhiyaOperationTeam`),
							authorAvatar: '/assets/images/icon.png',
							createTime: `2024${year}10${month}18${day}`,
						},
					],
				},
				{
					classify: intl.get('classifyBlog'),
					icon: '/assets/images/infos/classify_blog.png',
					moreUrl: '',
					list: [
						{
							id: 'ad5150cda73dc0aec21569b1909a0123',
							title: intl.get('blog9Title'),
							subtitle: intl.get(`blog9Subtitle`),
							titleImage: '/assets/images/infos/blog_9.png',
							content: intl.get(`blog9Content`),
							image: '/assets/images/infos/blog_9.png',
							author: intl.get(`teamMember2Name`),
							authorAvatar: '/assets/images/shiguangzhai.jpeg',
							createTime: `2024${year}10${month}17${day}`,
						},
						{
							id: '94e957776aba7faab14ade01752dddd9',
							title: intl.get('blog8Title'),
							subtitle: intl.get(`blog8Subtitle`),
							content: intl.get(`blog8Content`),
							titleImage: '/assets/images/infos/blog_8.png',
							image: '/assets/images/infos/blog_8.png',
							author: intl.get(`teamMember2Name`),
							authorAvatar: '/assets/images/shiguangzhai.jpeg',
							createTime: `2024${year}3${month}27${day}`,
						},
						{
							id: 'b534b6a48ec6f3f29143f4864b6ffa2e',
							title: intl.get('blog7Title'),
							subtitle: intl.get(`blog7Subtitle`),
							content: intl.get(`blog7Content`),
							titleImage: '/assets/images/infos/blog_7.png',
							image: '/assets/images/infos/blog_7.png',
							author: intl.get(`teamMember2Name`),
							authorAvatar: '/assets/images/shiguangzhai.jpeg',
							createTime: `2024${year}3${month}10${day}`,
						},
						{
							id: '1835cfc5917f6b4639e1c8fd21d5fee7',
							title: intl.get('blog6Title'),
							subtitle: intl.get(`blog6Subtitle`),
							content: intl.get(`blog6Content`),
							titleImage: '/assets/images/infos/blog_6.png',
							image: '/assets/images/infos/blog_6.png',
							author: intl.get(`teamMember2Name`),
							authorAvatar: '/assets/images/shiguangzhai.jpeg',
							createTime: `2024${year}3${month}5${day}`,
						},
						{
							id: 'd95653ae811d2b4a3ede96d17ca66328',
							title: intl.get('blog5Title'),
							subtitle: intl.get(`blog5Subtitle`),
							content: intl.get(`blog5Content`),
							titleImage: '/assets/images/infos/blog_5.png',
							image: '/assets/images/infos/blog_5.png',
							author: intl.get(`teamMember2Name`),
							authorAvatar: '/assets/images/shiguangzhai.jpeg',
							createTime: `2024${year}1${month}20${day}`,
						},
						{
							id: '24505de2967a5e4d100c3af2256ca5b1',
							title: intl.get('blog2Title'),
							subtitle: intl.get(`blog2Subtitle`),
							content: intl.get(`blog2Content`),
							titleImage: '/assets/images/infos/blog_2.png',
							image: '/assets/images/infos/blog_2.png',
							author: intl.get(`teamMember1Name`),
							authorAvatar: '/assets/images/wenrong.jpeg',
							createTime: `2023${year}9${month}10${day}`,
						},
						{
							id: '9b459515e798b0974baeb181a246f057',
							title: intl.get('blog3Title'),
							subtitle: intl.get(`blog3Subtitle`),
							content: intl.get(`blog3Content`),
							titleImage: '/assets/images/infos/blog_3.png',
							image: '/assets/images/infos/blog_3.png',
							author: intl.get(`teamMember2Name`),
							authorAvatar: '/assets/images/shiguangzhai.jpeg',
							createTime: `2023${year}8${month}12${day}`,
						},
						{
							id: 'cd5850ada73dc0aec37c79b1808cea3c',
							title: intl.get('blog4Title'),
							subtitle: intl.get(`blog4Subtitle`),
							content: intl.get(`blog4Content`),
							titleImage: '/assets/images/infos/blog_4.png',
							image: '/assets/images/infos/blog_4.png',
							author: intl.get(`teamMember2Name`),
							authorAvatar: '/assets/images/shiguangzhai.jpeg',
							createTime: `2023${year}7${month}18${day}`,
						}
					]
				},
			],
	}
    resolve({
      code: 0,
      data: infos,
      message: 'succeed',
    });
  };

const getLocalInfo =
	(id: string) => (resolve: PromiseCbkInfo, reject: PromiseCbkInfo) => {
		new Promise<BaseResponse<Infos>>(getLocalInfos())
			.then((res) => {
				let infos = res.data || {list: []};
				let info = undefined;
				for (let i = 0; i < infos.list.length && !info; i++) {
					let item = infos.list[i];
					info = item.list.find(e => e.id === id)
				}
				resolve({
					code: 0,
					data: info,
					message: 'succeed',
				});
			})
  };

export const loadInfos = () =>
  new Promise<BaseResponse<Infos>>(getLocalInfos());

export const getInfo = (id: string) =>
  new Promise<BaseResponse<InfoItem>>(getLocalInfo(id));